
import React from 'react';
import styled from 'styled-components'
import food from '../../assets/images/food.jpg'
import parent from '../../assets/images/parent.jpg'
import kid from '../../assets/images/kid.jpg'
import pages from '../../assets/images/pages.jpg'
import garden from '../../assets/images/garden.jpg'
import forskolan from '../../assets/images/forskolan.jpg'
import Spacer from '../spacer/Spacer.js'
import { colorCategoryMapper } from 'config/config.js'

const infoList = [
    {
        imgSrc: forskolan,
        title: 'Pedagogiken',
        subTitle: 'Waldorfpedagogiken vilar på en antroposofisk livssyn och har sin grund i Rudolf Steiners'
                + 'tankar kring hur pedagogisk verksamhet kan utformas så att barnet ges möjlighet att'
                + 'utvecklas så allsidigt som möjligt i sin vilja, känsla och tanke.',
        color: colorCategoryMapper['/pedagogik'].color,
        href: '/pedagogik'
    },
    {
        imgSrc: kid,
        title: 'Den fria leken',
        subTitle: 'Vi vurmar för den fria leken! Här erövrar barnet sin egen identitet, lägger grunden till social interaktion, '
                + 'initiativrikedom och får möjlighet att använda hela sin fantasi.',
        color: colorCategoryMapper['/lek'].color,
        href: '/lek'
    },
    {
        imgSrc: garden,
        title: 'Språk & Berättande',
        subTitle: 'I Waldorfpedagogiken är sång, ramsor och berättandet av en saga viktiga inslag under hela dagen. '
        + 'Barnet får möta pedagoger som levandegör språket vilket främjar barnets språkutveckling.',
        color: colorCategoryMapper['/sprak'].color,
        href: '/sprak'
    },
    {
        imgSrc: parent,
        title: 'Att vara förälder',
        subTitle: 'Som förälder på Waldorfförskolan Linden läggs grunden till vår relation under ert första besök på Linden då vår förskolechef '
        + 'visar er runt! Ni kontaktas sedan av en pedagog som välkomnar er till en av våra avdelningar.',
        color: colorCategoryMapper['/foralder'].color,
        href: '/foralder'
    },
    {
        imgSrc: food,
        title: 'Maten & Miljön',
        subTitle: 'Vi värnar om omsorgen kring allt som omger oss, allt levande och alla ting.'
                + 'Vi vårdar vår miljö, våra leksaker, vårt språkbruk och varandra för att skapa en för barnet god och rofylld miljö att vara i.',
        color: colorCategoryMapper['/miljo'].color,
        href: '/miljo'
    },
    {
        imgSrc: pages,
        title: 'Styrdokument & Riktlinjer',
        subTitle: 'Waldorfförskolan Linden bedrivs som en ekonomisk förening av våra anställda som medlemmar. Vår styrelse, bestående av tre ledamöter är vår huvudman med övergripande ansvar över föreningen. ' 
                + 'Vi har kontinuerliga styrelsemöten och har ett nära samarbete med förskolechef.',
        color: colorCategoryMapper['/styrelsen'].color,
        href: '/miljo'
    },
]

const CardsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const CardImage = styled.div`
    height: 350px;
    background-image: url(${props => props.imgSrc});
    background-size: cover;
    background-position: center center;
`

const CardWrapper = styled.a`
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 24px 5%;
    text-decoration:none;
    @media (max-width: 850px) {
        flex-direction: row;
        display: flex;
        width: 80%;
        padding: 0;
        margin: 20px auto;
        
    }
    @media (max-width: 500px) {
        width: 100%;
    }
`

const Title = styled.div`
    font-size: 20px;
`

const SelectWrapper = styled.div`
    text-align: center;
    color: rgba(70,70,70,1);
    &:hover {
        background: ${props => props.color};
        transition: all 0.2s ease;
        color: white;
    }
`

const SubTitle = styled.div`
    font-size: 16px;
`

const Card = ({imgSrc, title, subTitle, color, href}) => {
    return (
        <CardWrapper href={href}>
            <SelectWrapper color={color}>
                <CardImage imgSrc={imgSrc}/>
                <Spacer size='16px'/>
                <Title>{title}</Title>
                <Spacer size='16px'/>
                <Spacer size='16px'  fill='true'/>
                <SubTitle>{subTitle}</SubTitle>
                <Spacer size='16px'/>
            </SelectWrapper>
        </CardWrapper>
    )
}




const Cards = () => {
    return (
        <CardsWrapper>
            {infoList.map((value, index) => {
                return (
                    <Card
                        key={index}
                        title={value.title}
                        subTitle={value.subTitle}
                        imgSrc={value.imgSrc}
                        color={value.color}
                        href={value.href}
                    />
                )
            })}
        </CardsWrapper>
    )
}

export default Cards; 