import styled from 'styled-components'

const Spacer = styled.div`
    width: ${props => props.width ? props.width : '80%' };
    margin: auto;
    height: ${props => props.size};
    border-top: ${props => props.fill === 'true' ? '1px solid rgb(215, 215, 215)' : null};
    border-color: ${props => props.color}
`

export default Spacer;