
import React from 'react';
import styled from 'styled-components'
import leaves from '../../assets/images/leaves.jpg'

const HeaderImg = styled.div`
    background-image: url(${props => props.imgSrc});
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 78px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    @media (max-width: 800px) {
        height: 78px;
    }
`   

const Header = ({imgSrc = leaves, size='large'}) => 
    <HeaderImg imgSrc={imgSrc} size={size}/>

export default Header; 