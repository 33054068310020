
import React from 'react';
import styled from 'styled-components'
import {withRouter} from 'react-router-dom'
import logo from '../../assets/images/logo.jpg'
import Dropdown from './Dropdown.js'
import { colorCategoryMapper } from 'config/config.js'

const BarWrapper = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    width: 100%;
`

const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const NavigatorGroup = styled.div`
    justify-content: flex-end;
    flex-direction: row;
    display: flex;
    margin: 15px;
    @media (max-width: 800px) {
        display: none;
    }
`

const LogoWrapper = styled.a`
    justify-content: flex-start;
    align-items: center;
    display: flex;
    margin: 15px;
    text-decoration: none;
`

const LogoText = styled.div`
    @media (max-width: 1500px) {
        display: none;
    }
    font-size: 32px;
    margin-left: 16px;
    color: white;
`

const LogoTextSmall = styled.div`
    @media (min-width: 800px) {
        display: none;
    }
    @media (min-width: 400px) {
        font-size: 18px;
    }
    font-size: 12px;
    margin-left: 4px;
    color: white;
`

const Logo = styled.img`
    width: 60px;
    height: 50px;
    margin-left: 32px;

    @media (max-width: 800px) {
        display: none;
    }
    
`

const NavbarItem = styled.a`
    cursor: pointer;
    background-color: ${props => props.color};
    border-radius: 10px;
    display: flex;
    padding: 10px;
    margin: 5px;
    @media (max-width: 1122px) {
        margin: 0px;
        padding: 10px;
        border-radius: 0px;
    }
    color: white;
    text-decoration: none;
    align-items: center;
    &:hover {
        background-color: ${props => props.hover};
    }
`

const DropDownGroup = styled.div`
    justify-content: center;
    flex-direction: row;
    display: flex;
    width: 50%;
`



const Navbar = ({location}) => {
    return (
        <BarWrapper>
            <FlexWrapper>
                <LogoWrapper href='/'>
                    <Logo src={logo}/>
                    <LogoTextSmall>WALDORFFÖRSKOLAN</LogoTextSmall>
                    <LogoText>WALDORFFÖRSKOLAN</LogoText>
                </LogoWrapper>
                <NavigatorGroup>
                    <NavbarItem href='/' >Hem</NavbarItem>
                    <NavbarItem href='/pedagogik' color={colorCategoryMapper['/pedagogik'].color} hover={colorCategoryMapper['/pedagogik'].hoverColor} >Pedagogiken</NavbarItem>
                    <NavbarItem href='/lek' color={colorCategoryMapper['/lek'].color} hover={colorCategoryMapper['/lek'].hoverColor}>Den fria leken</NavbarItem>
                    <NavbarItem href='/sprak' color={colorCategoryMapper['/sprak'].color} hover={colorCategoryMapper['/sprak'].hoverColor} >Språk & Berättande</NavbarItem>
                    <NavbarItem href='/miljo' color={colorCategoryMapper['/miljo'].color} hover={colorCategoryMapper['/miljo'].hoverColor} >Maten & Miljön</NavbarItem>
                    <NavbarItem href='/foralder' color={colorCategoryMapper['/foralder'].color} hover={colorCategoryMapper['/foralder'].hoverColor}>Att vara förälder</NavbarItem>
                    <NavbarItem href='/styrelsen' color={colorCategoryMapper['/styrelsen'].color} hover= {colorCategoryMapper['/styrelsen'].hoverColor} >Styrdokument & riktlinjer</NavbarItem>
                </NavigatorGroup>
                <Dropdown/>
            </FlexWrapper>
        </BarWrapper>
    )
}

export default withRouter(Navbar); 