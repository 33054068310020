
import React from 'react';
import styled from 'styled-components'
import Spacer from '../spacer/Spacer.js'
import file from '../../assets/images/file.svg'




const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 16px 16px 32px 16px;
    width: 100%;
    @media (max-width: 900px) {
        margin: 16px 16px 32px 16px;
    }
`

const Title = styled.div`
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 12px;
`
const Text = styled.p`
    color: rgba(70,70,70,1);
`

const Link = styled.a`
    background-color: ${props => props.color};
    color: white;
    display: flex;
    padding: 20px 0px;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
`

const File = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 8px;
`

const DocumentCard = ({title, body, color, document}) => {
    return (
        <CardWrapper color={color}>
            <Title>
                {title} 
            </Title>
            <Spacer size='1px'  fill='true' width='100%'/>
            {body.map((value) => (
                <Text>
                    {value.spacer && <Spacer size={value.spacer}/>}
                    <b>{value.bullet}</b>{value.text}
                </Text>

            ))}
            <Spacer size='8px'/>
            <Link color={color} href={document} target='_blank'>
                <File src={file} />
                Dokument för {title.toLowerCase()}
            </Link>
        </CardWrapper>
    )
}


export default DocumentCard; 