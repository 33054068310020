
import React from 'react';
import styled from 'styled-components'
import file from '../../assets/images/file.svg'
import Spacer from '../spacer/Spacer.js'
import ansokan from '../../assets/pdf/ansokan.pdf'
import ko from '../../assets/pdf/ko.pdf'

const Wrapper = styled.div`
    background-color: #595394;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    margin: auto;
`

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 12px;
`

const Text = styled.p`
    text-align: center;
    margin: 16px 48px;
`

const LinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const Link = styled.a`
    color: white;
    display: flex;
    padding: 20px 0px;
    width: 50%;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-right: ${props => props.border ? 'solid 5px white': 'none'} ;
    border-top: solid 5px white;
`

const File = styled.img`
    width: 32px;
    height: 32px;
`

const Application = () => {
    return (
        <Wrapper>
            <Spacer size='24px'/>
            <Title>Ansökan och Dokument</Title>
            <Spacer size='1px'  fill='true'/>
            <Text>Nedan hittar du dokument för ansökan och vårt kö-system. För mer info kolla under Styrdokument & riktlinjer.</Text>
            <Spacer size='16px' />
            <LinkWrapper>
                <Link href={ansokan} target='_blank' border={true}>
                    <File src={file} />
                    Dokument för ansökan
                </Link>
                <Link href={ko} target='_blank'>
                    <File src={file} />
                    Dokument för kö
                </Link>
            </LinkWrapper>
        </Wrapper>
    )
}

export default Application; 