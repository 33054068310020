import books from '../../assets/images/books.jpg'
import pages from '../../assets/images/pages.jpg'
import food from '../../assets/images/food.jpg'
import leaves from '../../assets/images/leaves.jpg'
import kids from '../../assets/images/kids.jpg'
import kid from '../../assets/images/kid.jpg'
import crayon from '../../assets/images/crayon.jpg'
import orange from '../../assets/images/orange.jpg'
import forestkid from '../../assets/images/forestkid.jpg'
import parent from '../../assets/images/parent.jpg'
import flower from '../../assets/images/flower.jpg'
import leaves_string from '../../assets/images/leaves_string.jpg'
import cake from '../../assets/images/cake.jpg'
import garden from '../../assets/images/garden.jpg'
import ring from '../../assets/images/ring.jpg'
import ansokan from '../../assets/pdf/ansokan.pdf'
import regler from '../../assets/pdf/regler.pdf'
import ko from '../../assets/pdf/ko.pdf'
import sjukdom from '../../assets/pdf/sjukdom.pdf'
import kvalitetsarbete from '../../assets/pdf/kvalitetsarbete.pdf'
import likabehandlingsplan from '../../assets/pdf/likabehandlingsplan.pdf'



import { colorCategoryMapper } from 'config/config.js'

const infoPageConfig = [
    {
        title: 'Pedagogiken',
        path: '/pedagogik',
        headerImg: crayon,
        info: [
            'Waldorfpedagogiken vilar på en antroposofisk livssyn och har sin grund i Rudolf Steiners tankar kring hur pedagogisk verksamhet kan utformas så att barnet ges möjlighet att utvecklas så allsidigt som möjligt i sin vilja, känsla och tanke. Genom skapande lek utvecklar barnen samarbetsförmåga, empati och begrepp som blir till en rik källa att ösa ur för resten av livet. Målsättningen i förskolan är att ge barnen rätt att få vara barn och att få uppleva en trygg barndom, att få mogna i sin egen takt för att så småningom ta del av vuxenvärlden med allt vad det innebär.',
            'Inredning och lekmaterial är valda med stor omsorg för att främja ett undersökande sinnligt lärande som inspirerar till lek och goda möten. Verksamheten utgår från årsloppet där barnens behov och intressen formar innehållet.',
            'Varje barn behöver en för barnet förtrogen vuxen som ger barnet ett hölje av värme, glädje och goda vanor – en dagsrytm och årsrytm där barnet kan bygga upp en egen bas för den egna tryggheten.'
        ],
        cards: [
            {
                imgSrc: kids,
                title: 'Förebilder och efterhärmning',
                align: 'left',
                body: [
                    'En av hörnstenarna i Waldorfpedagogiken är efterhärmning. Vi pedagoger har en viktig roll i barnets utveckling där vi förmedlar vår ' +
                    'gemensamma värdegrund genom att vara goda förebilder. Barnet kan sedan inspireras genom att iaktta och efterhärma. ' +
                    'I våra dagliga sysslor gör vi därför meningsfulla arbeten, både inomhus och utomhus som blir till inspiration för barnets lekar.'
                ],
                color: colorCategoryMapper['/pedagogik'].color 
            },
            {
                imgSrc: leaves,
                title: 'Ro och Rytm',
                align: 'right',
                body: [
                    'Vår verksamhet bygger på årstidernas växlingar. Vi knyter an till årets lopp med sagor, sånger och ramsor och har' + 
                    'återkommande fester vid årets traditionella högtider och händelser. Vi skapar ro och rytm genom att anamma det återkommande i dagen,' + 
                    'veckan och året, vilket skapar ett inre lugn och trygghet hos barnet. Varje dag innehåller en växling mellan fri lek och samlade ' +
                    'aktiviteter efter ett återkommande mönster, som gör att barnet känner igen sig och vet vad som skall hända under dagen. Då kan barnet ' +
                    'i lugn och ro hänge sig åt det som sker i stunden.'
                ],
                color: colorCategoryMapper['/pedagogik'].color 
            },
        ]
    },
    {
        title: 'Den fria leken',
        path: '/lek',
        info: [
            'Vi vurmar för den fria leken! Här erövrar barnet sin egen identitet, lägger grunden till social interaktion, initiativrikedom och får möjlighet att använda hela sin fantasi.',
            'I den fria leken har varje barn möjlighet till ett reellt inflytande över sin dag på förskolan genom sina egna val av aktiviteter och lekar. Vi pedagoger strävar efter att ' +
            'vara goda förebilder och att med glädje och inlevelse utföra vårt arbete på förskolan så att barnets härmningsförmåga får impulser till kreativ lek. Våra leksaker är enkla ' +
            'och av naturmaterial så att barnet med sin egen fantasi förvandlar föremålen till det som behövs i leken.',
            'Då barnet använder sin fantasi i den skapande leken börjar den egentliga viljan att förberedas. Fantasikrafterna bildar underlag ' +
            'för barnets vidareutveckling. Barnets egen inre aktivitet under de första sju åren ligger till grund för att barnet av egen kraft skall kunna vara medskapande i kunskapsprocesserna. '
        ],
        cards: [
            {
                imgSrc: kid,
                title: 'Lekmaterial',
                align: 'left',
                body: [
                    'Vi strävar efter att främja barnets lekutveckling och kreativitet. Därför består våra leksaker av enkla föremål i naturmaterial som tilltalar barnets alla ' +
                    'sinnen och inspirerar till eget skapande. Vi föredrar naturliga material såsom trä, sten, ullgarn, bomull, siden och bivaxkritor där ' +
                    'barnet får uppleva olikheten i känslan av materialet så som taktilt, varmt och vackert!'
                ],
                color: colorCategoryMapper['/lek'].color 
            },
        ]
    },
    {
        title: 'Maten & Miljön',
        path: '/miljo',
        headerImg: orange,
        info: [],
        cards: [
            {
                imgSrc: food,
                title: 'Måltiden',
                align: 'left',
                body: [
                    'Måltiden har stor betydelse i Waldorfförskolan för att ge det lilla barnet livskrafter. På Linden serveras lakto-ovo-vegetarisk ' +
                    'kost där all mat tillagas från grunden i vårt kök. Vi strävar efter att använda endast ekologiska råvaror och planerar vår ' +
                    'matsedel utifrån årstidernas tillgång på grönsaker och frukt. '
                ],
                color: colorCategoryMapper['/miljo'].color 
            },
            {
                imgSrc: forestkid,
                title: 'Miljön',
                align: 'right',
                body: [
                    'Vår miljö är harmoniskt utformad enligt Waldorfpedagogisk anda där färg, form, textilier och lekmaterial ger barnet ' +
                    'förutsättning att utvecklas. Utemiljön är föränderlig utefter barnens behov och här möter vi och utforskar vad naturen ' +
                    'ger oss. Pedagoger och barn vårdar gården dagligen i vardagliga sysslor samt tillsammans med föräldrar i återkommande trädgårdskvällar. ',
                    'Vi värnar om omsorgen kring allt som omger oss, allt levande och alla ting. Vi vårdar vår miljö, våra leksaker, vårt språkbruk och varandra för att skapa en för barnet god och rofylld miljö att vara i. '
                ],
                color: colorCategoryMapper['/miljo'].color 
            },
        ]
    },
    {
        title: 'Språk & Berättande',
        path: '/sprak',
        headerImg: leaves,
        info: [
            'I Waldorfpedagogiken är sång, ramsor och berättandet av en saga viktiga inslag under hela dagen. Barnet får möta pedagoger som levandegör ' +
            'språket vilket främjar barnets språkutveckling. Med ett rikt språk stärker vi barnets självkänsla i att bygga relationer, ingå i social gemenskap och känna meningsfullhet.'
        ],
        cards: [
            {
                imgSrc: garden,
                title: 'Berättande',
                align: 'right',
                body: [
                    'De muntligt berättade sagorna ger barnet möjlighet att skapa sina egna inre bilder och få tillgång till sin fantasi. Sånger, ramsor och sagor ' +
                    'är anpassade till årsloppet och låter barnen uppleva rytmen i musik och ord.  Den rytmiska upprepningen är även betydelsefull för att ' +
                    'utveckla minnet när sagorna berättas många gånger och sagans innehåll får en djupare mening.'
                ],
                color: colorCategoryMapper['/sprak'].color 
            },
            {
                imgSrc: ring,
                title: 'Skärmar',
                align: 'left',
                body: [
                    'Vi tror att mellanmänskliga relationer hjälper det lilla barnet att utveckla ett språk. Därför är vi en skärm -och datorfri förskola som bygger vår verksamhet på sinnliga upplevelser där sång, ramsor och berättandet är centralt.'
                ],
                color: colorCategoryMapper['/sprak'].color 
            },
        ]
    },
    {
        title: 'Att vara förälder',
        path: '/foralder',
        headerImg: leaves_string,
        info: [
        'Som förälder på Waldorfförskolan Linden läggs grunden till vår relation under ert första besök på Linden då vår förskolechef visar er runt! Ni kontaktas sedan av en pedagog som välkomnar er till en av våra avdelningar.',
        'Som ny familj på Blomman och Fröet deltar ni i en Lindebarnsträff under maj månad. Här ges ni möjlighet att tillverka Ert barns lindebarn, möta andra föräldrar och de pedagoger som arbetar kring de yngsta barnen. '
        ],
        cards: [
            {
                imgSrc: flower,
                title: 'Höstmarknad & Trädgårdskväll',
                align: 'left',
                body: [
                    'I oktober månad hålls vår årliga Höstmarknad och Öppet hus där familj, släkt och vänner är välkomna! Ni föräldrar bjuds in att bidra med bakverk till vårt uppskattade café.',
                    'Under höst och vår hålls en Trädgårdskväll där vi tillsammans vårdar vår förskolegård. Vi bjuder er på värmande soppa och bröd.'
                ],
                color: colorCategoryMapper['/foralder'].color 
            },
            {
                imgSrc: cake,
                title: 'Sommarfest',
                align: 'right',
                body: [
                    'Under juni månad avslutar vi året med vår fantastiska Sommarfest där vi firar att sommaren är här! Vi sjunger, dansar och önskar särskilt lycka till de barn som ska ' +
                    'vidare mot nya äventyr som förskoleklass. Vi bjuder även på en dignande tårtbuffé som ni föräldrar bakat.'
                ],
                color: colorCategoryMapper['/foralder'].color 
            },
            {
                imgSrc: parent,
                title: 'Inblick',
                align: 'left',
                body: [
                    'Ni bjuds till en inblick i ert barns vardag på Linden genom de Föräldramöten som hålls höst och vår. Varje termin håller Föräldrarådet träff där ni föräldrar är välkomna att delta.',
                    'Vi välkomnar er till Utvecklingssamtal kring ert barn.',
                    'Som förälder får ni en unik möjlighet till inblick i ert barns verksamhet när vårdar ni ert barns avdelning lite extra genom att Städa där 2–3 gånger/termin. '
                ],
                color: colorCategoryMapper['/foralder'].color 
            },
        ]
    },
    {
        title: 'Styrdokument och styrelse',
        path: '/styrelsen',
        headerImg: pages,
        info: [
            'Waldorfförskolan Linden bedrivs som en ekonomisk förening av våra anställda som medlemmar. Vår styrelse, bestående av tre ledamöter är vår huvudman med övergripande ansvar över föreningen. Vi har kontinuerliga styrelsemöten och har ett nära samarbete med förskolechef.',
            'Nedan finns information och dokument om ansökning och regler gällande Waldorfförskolan Linden.',
        ],
        cards: [
            {
                title: 'Ansökan',
                type: 'document',
                document: ansokan,
                body: [
                    {
                        text: 'Klicka här för att öppna dokument för att ansöka om plats till Waldorfförskolan Linden.'
                    }
                ],
                color: colorCategoryMapper['/styrelsen'].color
            },
            {
                title: 'Kö-system',
                type: 'document',
                document: ko,
                body: [
                    {
                        text: 'Klicka här för att öppna dokument om att ställa barn i kö hos vår förskola.'
                    }
                ],
                color: colorCategoryMapper['/styrelsen'].color
            },
            {
                title: 'Regler',
                align: 'left',
                type: 'document',
                document: regler,
                body: [
                    {
                        bullet: 'Förskola: ',
                        spacer: '8px',
                        text: 'Vi tar emot barn i åldern 1-5 år. Inskolningen är normalt ca 2 veckor. Avgift tas ut efter två inskolningsveckor.',
                    },
                    {
                        bullet: '15-timmarsplacering: ',
                        text: 'Erbjuds de barn som har vårdnadshavare som inte arbetar eller studerar. Det innebär att barnet får vara på förskolan 15 timmar/vecka. Vi erbjuder tider tisdag-torsdag. 8:00-13:00 eller 8:30-13:30.',
                        spacer: '8px'
                    },
                    {
                        bullet: 'Vistelsetid: ',
                        text: 'Barnets vistelsetid i förskolan beräknas utifrån vårdnadshavarens arbetstid/studietid, restid och tid för hämtning och lämning. När vårdnadshavaren har lediga dagar/semester har barnet INTE rätt till omsorg.',
                        spacer: '8px'
                    },
                    {
                        bullet: 'Uppsägning',
                        text: 'Uppsägningstiden för en plats är 60 dagar och räknas från det datum en uppsägningsblankett lämnas in. Avgift betalas under uppsägningsplatsen oavsett om den används eller ej.',
                        spacer: '8px'
                    },
                    {
                        bullet: 'Familj:',
                        spacer: '8px',
                        text: 'Vi måste veta vilka vuxna som bor på adressen som barnet är folkbokförd på. Deras sammanlagda månadsinkomst före skatt utgör grunden för barnomsorgsavgiften. Vi måste också veta om barnet har syskon inom barnomsorgen, då det kan påverka avgiftens storlek. ',
                    },
                    {
                        bullet: 'Avgift: ',
                        spacer: '8px',
                        text: 'Avgiften baseras på den sammanlagda inkomsten före skatt i hushållet där barnet är folkbokförd, och räkningen skickas till den adressen. Vi erbjuder delad räkning.',
                    },
                    {
                        bullet: 'Övriga upplysningar: ',
                        text: 'Om ditt barn har särskilda behov, som vi behöver ta del av, är det viktigt att du informerar om detta. ',
                    }
                ],
                color: colorCategoryMapper['/styrelsen'].color 
            },
            {
                title: 'Sjukdom',
                align: 'left',
                type: 'document',
                document: sjukdom,
                body: [
                    {
                        bullet: 'Sjukdom: ',
                        spacer: '8px',
                        text: 'Vid sjukdom meddelas avdelningen via sms eller samtal, så tidigt som möjligt. Vid längre tids sjukdom är det viktigt att meddela avdelningen så att vi vet hur länge barnet blir borta. (Läs mera om vår policy för sjukdomar/smitta i infoblad om detta.)',
                    },
                    {
                        bullet: 'Ledighet: ',
                        text: 'Vid ledighet lämnas information till berörd avdelning.',
                        spacer: '8px'
                    },
                    {
                        bullet: 'Sen ankomst: ',
                        text: 'Vid sen ankomst/hämtning vill vi att ni alltid hör av er till berörd avdelning via sms eller samtal. ',
                        spacer: '8px'
                    },
                    {
                        bullet: 'Kontakt uppgifter',
                        spacer: '16px'
                    },
                    {
                        bullet: 'Duvan:',
                        text: '076-0292632',
                    },
                    {
                        bullet: 'Svalan: ',
                        text: '076-0213161',
                    },
                    {
                        bullet: 'Blomman: ',
                        text: '076-0343305',
                    },
                    {
                        bullet: 'Fröet: ',
                        text: '076-0200644',
                    },
                ],
                color: colorCategoryMapper['/styrelsen'].color 
            },
            {
                title: 'Likabehandlingsplan',
                type: 'document',
                document: likabehandlingsplan,
                body: [
                    {
                        text: 'Klicka här för att öppna dokument om Waldorfförskolan Lindens Likabehandlingsplan.'
                    }
                ],
                color: colorCategoryMapper['/styrelsen'].color
            },
            {
                title: 'Systematiskt kvalitetsarbete',
                type: 'document',
                document: kvalitetsarbete,
                body: [
                    {
                        text: 'Klicka här för att öppna dokument om Waldorfförskolan Linden Systematiskt kvalitetsarbete.'
                    }
                ],
                color: colorCategoryMapper['/styrelsen'].color
            },
        ]
    }
]



export default infoPageConfig;