
import React from 'react';
import styled from 'styled-components'
import Header from '../header/Header.js'
import Spacer from '../spacer/Spacer.js'
import HomeInfo from './HomeInfo.js'
import Cards from './Cards.js'
import Application from './Application.js'

const StyleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const SizeWrapper = styled.div`
    max-width: 1400px;
`

const Home = () => {
    return (
        <React.Fragment>
            <Header/>
            <Spacer size='24px'/>
            <StyleWrapper>
                <SizeWrapper>
                    <HomeInfo/>
                    <Spacer size='24px'/>
                    <Cards/>
                    <Spacer size='24px'/>
                    <Application/>
                </SizeWrapper>
            </StyleWrapper>
        </React.Fragment>
    )
}

export default Home; 