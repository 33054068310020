
import React from 'react';
import styled from 'styled-components'
import Spacer from '../spacer/Spacer.js'




const CardImage = styled.div`
    height: 400px;
    width: 50%;
    background-image: url(${props => props.imgSrc});
    background-size: cover;
    background-position: center center;
    @media (max-width: 750px) {
        width: 100%;
    }
`

const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 16px;
    @media (max-width: 750px) {
        flex-direction: column;
        display: flex;
        width: 100%;
    }
`

const Title = styled.div`
    font-size: 20px;
    @media (max-width: 750px) {
        padding: 12px 12px 0px 10px;
    }
`

const InfoWrapper = styled.div`
    width: 50%;
    padding: 10px;
    background-color: ${props => props.color};
    color: white;
    @media (max-width: 750px) {
        width: 100%;
        padding: 0 0 10px 0;
    }
`

const InfoText = styled.p`
    @media (max-width: 750px) {
        padding: 0px 12px 0px 12px;
    }
    font-size: 16px;
    
`

const InfoCard = ({imgSrc, title, body, color, align}) => {
    const alignRight = window.screen.width > 750 && align === 'right'
    return (
        <CardWrapper>
            {!alignRight && <CardImage imgSrc={imgSrc}/>}
            <InfoWrapper color={color}>
                <Title>
                   {title} 
                </Title>
                <Spacer size='16px' fill='true' width='100%'/>
                {body.map((value) => (
                    <InfoText>{value}</InfoText>
                ))}
            </InfoWrapper>
            {alignRight && <CardImage imgSrc={imgSrc}/>}
        </CardWrapper>
    )
}


export default InfoCard; 