

const colorCategoryMapper = {
    '/pedagogik': {
        color: '#CC5252',
        hoverColor: '#B34747',
        path: '/pedagogik'
    },
    '/miljo': {
        color: '#98BB91',
        hoverColor: '#83A17D',
        path: '/miljo'
    },
    '/sprak': {
        color: '#819ebf',
        hoverColor: '#7189A6',
        path: '/sprak'
    },
    '/foralder': {
        color: '#cba877',
        hoverColor: '#B39469',
        path: '/foralder'
    },
    '/lek': {
        color: '#fdbc5e',
        hoverColor: '#E3A754',
        path: '/lek'
    },
    '/styrelsen': {
        color: '#595394',
        hoverColor: '#4A457A',
        path: '/styrelsen',
    }
}


export {colorCategoryMapper};