
import React from 'react';
import styled from 'styled-components'

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
    @media (max-width: 850px) {
        flex-direction: column;
        display: flex;
        width: 80%;
    }
`

const InfoTitle = styled.div`
    @media (max-width: 500px) {
        font-size: 24px;
    }
    display: flex;
    font-size: 36px;
    font-weight: bold;
    border-bottom: solid 5px black;
    flex: 2;
`

const IntoText = styled.div`
    display: flex;
    flex: 5;
    font-size: 18px;
    margin-left: 16px;
    color: rgba(70,70,70,1);
    @media (max-width: 850px) {
        margin: 12px 0;
        font-size: 16px;
    }
`

const HomeInfo = () => {
    return (
        <InfoWrapper>
            <InfoTitle>
                <div>Waldorfförskolan Linden</div>
            </InfoTitle>
            <IntoText>
                    Waldorfförskolan Linden i Örebro ligger belägen i Adolfsberg, endast ett stenkast från
                    Drottning Kristinas gamla jaktskog. Vi vistas dagligen i skogsmark samt på vår förskolegård
                    där vi genom leken följer årsloppet och upplever naturens element.
                    Våra grundstenar är värme, glädje, ro, rytm och rörelse. Före skolåldern sker den största
                    fysiska förändringen i det lilla barnets liv. Hela barnet inandas omgivningen i färger, former,
                    material, ljud, dofter, stämningar och tar intryck som återspeglar sig i barnets sätt att vara.
                    Vi är varandras förebilder!
            </IntoText>
        </InfoWrapper>
    )
}

export default HomeInfo; 