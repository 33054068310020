
import React from 'react';
import styled from 'styled-components'
import facebook from 'assets/images/facebook.svg'
import instagram from 'assets/images/instagram.svg'
import Spacer from '../spacer/Spacer.js'

const Wrapper = styled.div`
    padding: 40px 0px;
    width: 100%;
    background-color: #888888;
    display: flex;
    flex-direction: row;
    @media (max-width: 850px) {
        display: flex;
        flex-direction: column;
        padding: 0px;
    }
`

const Row = styled.div`
    color: white;
    display: flex;  
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    border-right: solid 3px white;
    @media (max-width: 850px) {
        border-bottom: solid 3px white;
        border-right: none;
        padding: 20px;
        text-align: center;
    }
`

const ContactInfo = styled.div`
    
`

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
`

const Text = styled.div`
    font-size: 14px;
    color: #D3D3D3;
    margin-bottom: 4px;
`

const SocialText = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #D3D3D3;
`

const Icon = styled.img`
    margin: 8px;
    width: 36px;
    height: 36px;
`


const Footer = () => 
    <React.Fragment>
        <Spacer size='24px'/>
        <Wrapper>
            <Row>
                <ContactInfo>
                        <Title>Kontaktinformation</Title>
                        <Text><b>Mail</b>: forskolechef@waldorfforskolanlinden.se</Text>
                        <Text><b>Telefonnummer</b>: 076-0199300 (rektor)</Text>
                        <Text><b>Adress</b>: Stentorpsvägen 2, 702 30 Örebro</Text>
                </ContactInfo>
            </Row>
            <Row>
                <ContactInfo>
                    <Title>Avdelningar</Title>
                    <Text><b>Duvan</b>: 076-0292632</Text>
                    <Text><b>Svalan</b>: 076-0213161</Text>
                    <Text><b>Blomman</b>: 076-0343305</Text>
                    <Text><b>Fröet</b>: 076-0200644</Text>
                </ContactInfo>
            </Row>
            <Row>
                <Title>Följ oss</Title>
                <SocialText href='https://www.facebook.com/waldorflinden/' target='_blank'>
                    <Icon src={facebook}/>
                    <b>facebook</b>
                </SocialText>
                <SocialText href='https://www.instagram.com/waldorf.linden_orebro/' target='_blank'>
                    <Icon src={instagram}/>
                    <b>instagram</b>
                </SocialText>  
            </Row>
        </Wrapper>
    </React.Fragment>

export default Footer; 