

import React from 'react';
import styled from 'styled-components'
import InfoCard from './InfoCard.js'
import DocumentCard from './DocumentCard.js'
import Header from '../header/Header.js'
import Spacer from '../spacer/Spacer.js'

const Wrapper = styled.div`
    display: flex;
    width: 100%;   
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
`

const BodyWrapper = styled.div`
    width: 80%;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
        flex-direction: column;
        display: flex;
        width: 100%;
    }
`

const Title = styled.div`
    margin: 30px 0px;
    font-size: 36px;
    text-align: center;
    color: rgba(70,70,70,1);
    font-weight: bold;
`

const CardsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const InfoP = styled.p`
    font-size: 16px;
    margin: 16px 16px;
    color: rgba(70,70,70,1);
`

const InfoCards = ({cards}) => {
    return (
        <CardsWrapper>
            {cards.map((value, index) => {
                return (
                    value.type === 'document' ? 
                    <DocumentCard
                        key={index}
                        title={value.title}
                        body={value.body}
                        imgSrc={value.imgSrc}
                        color={value.color}
                        align={value.align}
                        type={value.type}
                        document={value.document}
                    />
                    :
                    <InfoCard
                        key={index}
                        title={value.title}
                        body={value.body}
                        imgSrc={value.imgSrc}
                        color={value.color}
                        align={value.align}
                        type={value.type}
                        document={value.document}
                    />
                )
            })}
        </CardsWrapper>
    )
}

const InfoWrapper = styled.div`
    width: 100%;
    margin: auto;
`




const InfoPage = ({headerImg, title, info, cards, color}) => 
    <React.Fragment>
        <Header imgSrc={headerImg}/>
        <Wrapper>
            <BodyWrapper>
                <Title>
                    {title}
                </Title>
                <Spacer size='0px' fill='true'/>
                <InfoWrapper>
                    {info.map((value) => (
                        <InfoP>{value}</InfoP>
                    ))}
                </InfoWrapper>
                <InfoCards cards={cards}/>
            </BodyWrapper>
        </Wrapper>
    </React.Fragment>

export default InfoPage; 