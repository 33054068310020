import React from "react";
import styled from "styled-components";
import { colorCategoryMapper } from 'config/config.js'
import menu from '../../assets/images/open-menu.svg'


const StyledUl = styled.ul`
    list-style-type: none;
    margin: 0px 10px 0px 0px;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    
    &:hover {
        background-color: rgba(0,0,0,0.5);
    }
    @media (min-width: 800px) {
        display: none;
    }
`

const StyledLi = styled.li`
    float: left;
`

const Dropbtn = styled.img`
    text-align: center;
    width: 36px;
    height: 36px;
    margin: 19px 52px;
`

const DropDownContent = styled.div`
    display: none;
    position: absolute;
    width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
`;

const DropDownLi = styled(StyledLi)`
    display: inline-block;
    &:hover ${DropDownContent} {
        display: block;
    }
`

const NavbarItem = styled.a`
    background-color: ${props => props.color};
    text-decoration: none;
    display: block;
    color: white;
    text-align: left;
    margin: 0px;
    padding: 10px;
    border-radius: 0px;
    &:hover {
        background-color: ${props => props.hover};
    }
`;

const Dropdown = () => (
    <StyledUl>
        <DropDownLi>
            <Dropbtn src={menu} />
            <DropDownContent>
            <NavbarItem href='/pedagogik' color={colorCategoryMapper['/pedagogik'].color} hover={colorCategoryMapper['/pedagogik'].hoverColor} >Pedagogiken</NavbarItem>
            <NavbarItem href='/lek' color={colorCategoryMapper['/lek'].color} hover={colorCategoryMapper['/lek'].hoverColor}>Den fria leken</NavbarItem>
            <NavbarItem href='/sprak' color={colorCategoryMapper['/sprak'].color} hover={colorCategoryMapper['/sprak'].hoverColor} >Språk & Berättande</NavbarItem>
            <NavbarItem href='/miljo' color={colorCategoryMapper['/miljo'].color} hover={colorCategoryMapper['/miljo'].hoverColor} >Maten & Miljön</NavbarItem>
            <NavbarItem href='/foralder' color={colorCategoryMapper['/foralder'].color} hover={colorCategoryMapper['/foralder'].hoverColor}>Att vara förälder</NavbarItem>
            <NavbarItem href='/styrelsen' color={colorCategoryMapper['/styrelsen'].color} hover= {colorCategoryMapper['/styrelsen'].hoverColor} >Styrdokument & riktlinjer</NavbarItem>
            </DropDownContent>
        </DropDownLi>
    </StyledUl>

)

export default Dropdown;