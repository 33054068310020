import React from "react";
import Navbar from "../components/navbar/Navbar.js";
import Footer from "../components/footer/Footer.js";
import Home from "../components/home/Home.js";
import InfoPage from "../components/infoPage/InfoPage.js"
import styled from 'styled-components'
import infoPageConfig from '../components/infoPage/InfoPageConfig.js'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const AppWrapper = styled.div`
  min-height: 100vh;
`

const AppStyler = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: white;
  align-items: center;
`

export default function App() {
  return (
    <Router>
      <AppWrapper>
        <Navbar/>
        <AppStyler>
            <Switch>
            {infoPageConfig.map((value) => {
              return (
                <Route path={value.path}>
                    <InfoPage
                      headerImg={value.headerImg}
                      title={value.title}
                      info={value.info}
                      cards={value.cards}
                    />
                </Route>
              )
            })}
              <Route path="">
                  <Home />
              </Route>
            </Switch>
        </AppStyler>
        <Footer/>
      </AppWrapper>
    </Router>
  );
}